// Fires page_view events after 'turbolinks:load' otherwise GTM only fires on initial page load
//
// https://medium.com/@vhbueno/rails-5-turbolinks-and-google-tag-manager-b3c4c30c3fb7

type TurbolinksLoadEvent = Event & { data: { url: string; timing: object } };

interface DataLayerWindow extends Window {
  dataLayer?: unknown[];
}

const dataLayerWindow = window as DataLayerWindow;

// we can't just name these whatever we like. clients need to set them up in their GTM first.
type TEventNamesDefinedByClients = 'pageView';

const pushToDataLayer = (
  eventName: TEventNamesDefinedByClients,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) => {
  dataLayerWindow.dataLayer = dataLayerWindow.dataLayer || [];
  dataLayerWindow.dataLayer.push({
    event: eventName,
    ...eventParams,
  });
};

dataLayerWindow.addEventListener('turbolinks:load', (event: TurbolinksLoadEvent) => {
  const parser = document.createElement('a');
  parser.href = event.data.url;

  pushToDataLayer('pageView', { virtualUrl: parser.pathname });
});
